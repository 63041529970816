<template>
  <div class="sidebar-section">
    <div class="sidebar-section-title">Downloads</div>
    <div class="sidebar-section-padded flex" data-cy="views-sidebar-option">
      <CustomButton @click="$emit('download')" buttonText="List" />
      <CustomButton v-if="enableDownloadOnTrackReports" @click="reportDownload()" buttonText="On-track Reports" />
      <Spinner class="spinner-loader" v-if="isLoading"></Spinner>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/customButton.vue";
import Spinner from "@/components/loaders/spinner.vue";

export default {
  props: {
    customViews: Array,
  },
  data() {
    return {
      checkInterval:null,
      isStart:false,
      isLoading:false,
      selectedFocus: "",
      modals: {
        addView: "",
        renameView: "",
      },
      enableDownloadOnTrackReports: false,
    };
  },
  components: {
    CustomButton,
    Spinner,
  },
  emits: [
    "applyView",
    "addView",
    "updateView",
    "renameView",
    "notify",
    "deleteView",
    "launchUpdateColumns",
    "download",
    "reportDownload",
  ],
  inject: ["posthog"],
  computed: {
    showingCustomView: function () {
      // decide if the current selected view is a custom view
      const myView = this.views.find((element) => element.name == this.selectedFocus);
      if (!myView) return false;
      if (!myView.custom) return false;
      return true;
    },
  },
  watch: {
    selectedFocus: function () {
      this.$emit("applyView", {
        selectedFocus: this.selectedFocus,
        views: this.views,
      });
    },
  },
  methods: {
    startPolling() {
        this.checkInterval = setInterval(() => {
          if(this.isStart){
          this.checkFlag();
        }else{
          clearInterval(this.checkInterval)
        }
        }, 1000); // Check every second
      },
    // loader flag set
    checkFlag() {
      const flag = localStorage.getItem('isLoading');
      this.isStart= flag ? Boolean(flag) :false
      if (flag === 'false') {
        this.isLoading = false; // Hide loader if flag is set to 'true'
        clearInterval(this.checkInterval)

      }
      if(flag === 'true'){
        this.isLoading = true;
      }
    },
    closeLoader(){
      this.isLoading=false
    },
    reportDownload(){
      this.isStart =true
      this.startPolling()
      localStorage.setItem('isLoading', true)
      this.isLoading=true
      this.$emit('reportDownload')
    },
    setSelectedFocus(name) {
      this.selectedFocus = name;
    },
  },
  created() {
    this.checkFlag();
    window.addEventListener('storage', this.checkFlag);
  },
  mounted(){
    localStorage.setItem("isLoading", false);
    this.checkFlag();

    this.posthog.onFeatureFlags(() => {
      if (this.posthog.isFeatureEnabled("download-on-track-reports")) {
        this.enableDownloadOnTrackReports = true;
      }
    }); 
  },
  unmounted() {
  // Remove the storage event listener when the component is unmounted
  window.removeEventListener('storage', this.checkFlag);
  localStorage.removeItem("isLoading");
},
};
</script>
<style scoped>
#loading {
  width: 15px;
  height: 15px;
}
.flex {
  display: flex;
  align-items: center;
}
</style>
